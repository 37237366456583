import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
     Test server app
    </div>
  );
}

export default App;
